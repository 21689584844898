import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import fetchSinglePost from "../lib/fetch/fetchSinglePost";
import Post from "../types/Post";
import Loader from "../components/Loader";
import RatingStars from "../components/RatingStars";
import toast from "react-hot-toast";
import { auth, db } from "../firebase.config";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import YesNoButton from "../components/YesNoButton";
import { MdStar, MdStarBorder, MdStarHalf } from "react-icons/md";

type FormData = {
  rating: number;
  title: string;
  description: string;
  detailed: boolean;
  ratingDetails: {
    plot: number;
    writing: number;
    characters: number;
    primaryGenre: number;
    secondaryGenre: number;
  };
  keptReading: boolean;
  wouldRecommend: boolean;
  wantAdapted: boolean;
};

function CreatePostReview() {
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    rating: 0,
    title: "",
    description: "",
    detailed: false,
    ratingDetails: {
      plot: 0,
      writing: 0,
      characters: 0,
      primaryGenre: 0,
      secondaryGenre: 0,
    },
    keptReading: false,
    wouldRecommend: false,
    wantAdapted: false,
  });

  const {
    rating,
    title,
    description,
    detailed,
    ratingDetails,
    keptReading,
    wouldRecommend,
    wantAdapted,
  } = formData;

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    if (params.postId) {
      fetchSinglePost(params.postId.toString()).then((res) => {
        setPost(res);
      });
    }
    setLoading(false);
  }, [navigate, params.postId]);

  const getButtonClass = (active: boolean) => {
    if (active) return "rounded-lg px-2 py-1 text-white text-sm bg-primary";
    else
      return "rounded-lg px-2 py-1 text-white text-sm bg-gray-400 hover:bg-gray-300 transition duration-200";
  };

  const createPostRatingAverage = () => {
    if (post) {
      let total = detailed ? getAverage() : rating;
      post.stats.ratingValues.forEach((val) => (total += val));
      return total / (post.stats.ratingValues.length + 1);
    }
  };

  const getAverage = () => {
    let total = 0;
    let num = 0;
    if (ratingDetails.plot !== 0) {
      total += ratingDetails.plot;
      num++;
    }
    if (ratingDetails.writing !== 0) {
      total += ratingDetails.writing;
      num++;
    }
    if (ratingDetails.characters !== 0) {
      total += ratingDetails.characters;
      num++;
    }
    if (ratingDetails.primaryGenre !== 0) {
      total += ratingDetails.primaryGenre;
      num++;
    }
    if (post?.secondaryGenre !== "None" && ratingDetails.secondaryGenre !== 0) {
      total += ratingDetails.secondaryGenre;
      num++;
    }

    return total / num;
  };

  const stars = (level: number) => {
    if (getAverage() >= level)
      return (
        <div className="text-yellow-400">
          <MdStar size="30" />
        </div>
      );
    else {
      if (getAverage() + 0.5 >= level)
        return (
          <div className="text-yellow-400">
            <MdStarHalf size="30" />
          </div>
        );
      else
        return (
          <div className="text-yellow-400">
            <MdStarBorder size="30" />
          </div>
        );
    }
  };

  const validateForm = (): boolean => {
    if (
      auth.currentUser &&
      post?.stats.bannedUsers.includes(auth.currentUser.uid)
    ) {
      toast.error("You've already uploaded a review!");
      return false;
    }
    if (title === "") return false;
    if (description === "") return false;
    if (detailed) {
      if (ratingDetails.plot === 0) return false;
      if (ratingDetails.writing === 0) return false;
      if (ratingDetails.characters === 0) return false;
      if (ratingDetails.primaryGenre === 0) return false;
      if (post?.secondaryGenre !== "None" && ratingDetails.secondaryGenre === 0)
        return false;
    } else if (rating === 0) return false;

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) toast.error("Please fill in all forms!");
    else {
      setLoading(true);

      const data = {
        title: title,
        description: description,
        detailed: detailed,
        rating: detailed ? getAverage() : rating,
        userId: auth.currentUser?.uid,
        userName: auth.currentUser?.displayName,
        ratingDetails: {
          plot: ratingDetails.plot,
          writing: ratingDetails.writing,
          characters: ratingDetails.characters,
          primaryGenre: ratingDetails.primaryGenre,
          secondaryGenre: ratingDetails.secondaryGenre,
        },
        keptReading: keptReading,
        wouldRecommend: wouldRecommend,
        wantAdapted: wantAdapted,
        stats: {
          wasHelpful: 0,
          totalResponses: 0,
          bannedUsers: [auth.currentUser?.uid],
        },
        timeStamp: new Date(Date.now()),
      };

      await addDoc(collection(db, `posts/${post?.id}/reviews`), data);
      if (post) {
        const postRef = doc(db, "posts", post?.id);
        await updateDoc(postRef, {
          stats: {
            pageCount: post.stats.pageCount,
            wordCount: post.stats.wordCount,
            bannedUsers: [...post.stats.bannedUsers, auth.currentUser?.uid],

            keptReading: keptReading
              ? post.stats.keptReading + 1
              : post.stats.keptReading,
            wouldRecommend: wouldRecommend
              ? post.stats.wouldRecommend + 1
              : post.stats.wouldRecommend,
            wantAdapted: wantAdapted
              ? post.stats.wantAdapted + 1
              : post.stats.wantAdapted,

            reviewCount: post.stats.reviewCount + 1,
            rating: post.stats.rating
              ? createPostRatingAverage()
              : detailed
              ? getAverage()
              : rating,
            ratingValues: [
              ...post.stats.ratingValues,
              detailed ? getAverage() : rating,
            ],

            ratingDetails: !detailed
              ? post.stats.ratingDetails
              : {
                  plot: [...post.stats.ratingDetails.plot, ratingDetails.plot],
                  writing: [
                    ...post.stats.ratingDetails.writing,
                    ratingDetails.writing,
                  ],
                  characters: [
                    ...post.stats.ratingDetails.characters,
                    ratingDetails.characters,
                  ],
                  primaryGenre: [
                    ...post.stats.ratingDetails.primaryGenre,
                    ratingDetails.primaryGenre,
                  ],
                  secondaryGenre:
                    post.secondaryGenre !== "None"
                      ? [
                          ...post.stats.ratingDetails.secondaryGenre,
                          ratingDetails.secondaryGenre,
                        ]
                      : post.stats.ratingDetails.secondaryGenre,
                },
          },
        });
      }

      setLoading(false);
      toast.success("Review created!");
      navigate(`/post/${post?.id}`);
    }
  };

  return (
    <>
      {!loading && post ? (
        <main className="max-w-md mx-auto">
          <div className="w-full pb-2">
            <h1 className="text-center text-lg font-semibold">
              Review for {post.title}
            </h1>
          </div>
          <form onSubmit={handleSubmit} className="flex flex-col">
            <label htmlFor="title" className="font-semibold pb-1">
              Title
            </label>
            <input
              type="text"
              className="form-input"
              placeholder="Title"
              id="title"
              value={title}
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
              autoComplete="off"
            />

            <label htmlFor="description" className="font-semibold pb-1">
              Description
            </label>
            <textarea
              className="form-input"
              placeholder="Description"
              id="description"
              value={description}
              rows={4}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              autoComplete="off"
            />

            {/* Detailed review selector */}
            <div className="flex justify-center">
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() => setFormData({ ...formData, detailed: false })}
                  className={`${getButtonClass(!detailed)} mr-2`}
                >
                  Simple
                </button>
                <button
                  type="button"
                  onClick={() => setFormData({ ...formData, detailed: true })}
                  className={getButtonClass(detailed)}
                >
                  Detailed
                </button>
              </div>
            </div>

            <div className="my-2">
              {detailed ? (
                <>
                  <h6 className="font-bold text-center">Overall Rating</h6>
                  <div className="flex justify-center items-center pb-1 mb-1 border-b border-b-gray-200">
                    {stars(1)}
                    {stars(2)}
                    {stars(3)}
                    {stars(4)}
                    {stars(5)}
                  </div>

                  {/* Plot */}
                  <RatingStars
                    initialValue={ratingDetails.plot}
                    title="Plot"
                    adjust={(e: number) =>
                      setFormData({
                        ...formData,
                        ratingDetails: { ...ratingDetails, plot: e },
                        rating: getAverage(),
                      })
                    }
                  />

                  {/* Writing */}
                  <RatingStars
                    initialValue={ratingDetails.writing}
                    title="Writing"
                    adjust={(e: number) =>
                      setFormData({
                        ...formData,
                        ratingDetails: { ...ratingDetails, writing: e },
                        rating: getAverage(),
                      })
                    }
                  />

                  {/* Characters */}
                  <RatingStars
                    initialValue={ratingDetails.characters}
                    title="Characters"
                    adjust={(e: number) =>
                      setFormData({
                        ...formData,
                        ratingDetails: { ...ratingDetails, characters: e },
                        rating: getAverage(),
                      })
                    }
                  />

                  {/* Primary Genre */}
                  <RatingStars
                    initialValue={ratingDetails.primaryGenre}
                    title={`${post.primaryGenre} Value`}
                    adjust={(e: number) =>
                      setFormData({
                        ...formData,
                        ratingDetails: { ...ratingDetails, primaryGenre: e },
                        rating: getAverage(),
                      })
                    }
                  />

                  {/* Secondary Genre */}
                  {post.secondaryGenre !== "None" && (
                    <RatingStars
                      initialValue={ratingDetails.secondaryGenre}
                      title={`${post.secondaryGenre} Value`}
                      adjust={(e: number) =>
                        setFormData({
                          ...formData,
                          ratingDetails: {
                            ...ratingDetails,
                            secondaryGenre: e,
                          },
                          rating: getAverage(),
                        })
                      }
                    />
                  )}
                </>
              ) : (
                <RatingStars
                  initialValue={rating}
                  title="Rating"
                  adjust={(e: number) =>
                    setFormData({ ...formData, rating: e })
                  }
                />
              )}
            </div>

            <YesNoButton
              initialValue={keptReading}
              title="Did you keep reading?"
              adjust={(e: boolean) =>
                setFormData({
                  ...formData,
                  keptReading: e,
                })
              }
            />

            <YesNoButton
              initialValue={wouldRecommend}
              title="Would you recommend this to a friend?"
              adjust={(e: boolean) =>
                setFormData({
                  ...formData,
                  wouldRecommend: e,
                })
              }
            />

            <YesNoButton
              initialValue={wantAdapted}
              title={`Would you like a ${
                post.postType === "Prose" ? "book" : "film"
              } made?`}
              adjust={(e: boolean) =>
                setFormData({
                  ...formData,
                  wantAdapted: e,
                })
              }
            />

            <button type="submit" className="btn-primary-md mt-4">
              Create
            </button>
          </form>
        </main>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default CreatePostReview;
