import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAYMW80glEX7SvM2mzsf9NIXLKAHjduuIw",
  authDomain: "scriptowl-b8555.firebaseapp.com",
  projectId: "scriptowl-b8555",
  storageBucket: "scriptowl-b8555.appspot.com",
  messagingSenderId: "227975339484",
  appId: "1:227975339484:web:4a1e99e8251636812f8319",
  measurementId: "G-K3HVSC0YT6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

export const db = getFirestore(app);
export const auth = getAuth(app);
