import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../../firebase.config";
import Post from "../../types/Post";
import docToPost from "../convert/docToPost";

const fetchMorePosts = async (lastDate: Date) => {
  try {
    // Get reference
    const postsRef = collection(db, "posts");

    console.log(lastDate);

    // Create a query
    const q = query(
      postsRef,
      orderBy("timeStamp", "desc"),
      startAfter(lastDate),
      limit(10)
    );

    // Execute query
    const querySnap = await getDocs(q);

    const posts: Array<Post> = [];

    querySnap.forEach((doc) => {
      return posts.push(docToPost(doc));
    });

    return posts;
  } catch (error) {
    console.log(error);
  }
};

export default fetchMorePosts;
