import Post from "../../types/Post";

const reviewText = (post: Post) => {
  if (post.stats.reviewCount === 0) return "No reviews";
  else {
    if (post.stats.reviewCount === 1) return "1 review";
    else return `${post.stats.reviewCount} reviews`;
  }
};

export default reviewText;
