import Post from "../../types/Post";
import { MdStar, MdStarBorder, MdStarHalf } from "react-icons/md";

interface IProps {
  post: Post;
}

const PostDetails = ({ post }: IProps) => {
  const stars = (level: number, val: number, large: boolean) => {
    if (val >= level)
      return (
        <div className="text-yellow-400">
          <MdStar size={large ? "25" : "18"} />
        </div>
      );
    else {
      if (val + 0.5 >= level)
        return (
          <div className="text-yellow-400">
            <MdStarHalf size={large ? "25" : "18"} />
          </div>
        );
      else
        return (
          <div className="text-yellow-400">
            <MdStarBorder size={large ? "25" : "18"} />
          </div>
        );
    }
  };

  const exists = (value: number): boolean => {
    return post.stats.reviewCount > 0 && value > 0;
  };

  const percent = (value: number): string => {
    return Math.floor((value / post.stats.reviewCount) * 100).toString();
  };

  const getAverage = (array: number[]): number => {
    let total = 0;
    array.forEach((num) => (total += num));
    return total / array.length;
  };

  const rating = getAverage(post.stats.ratingValues);
  const plot = getAverage(post.stats.ratingDetails.plot);
  const writing = getAverage(post.stats.ratingDetails.writing);
  const characters = getAverage(post.stats.ratingDetails.characters);
  const primaryGenre = getAverage(post.stats.ratingDetails.primaryGenre);
  const secondaryGenre =
    post.secondaryGenre === "None"
      ? 0
      : getAverage(post.stats.ratingDetails.secondaryGenre);

  return (
    <>
      <h6 className="font-bold text-center mb-4 pb-2 border-b border-b-gray-300 mx-auto max-w-xl">
        Details
      </h6>
      <div className="flex flex-col justify-centre">
        <div className="flex flex-col items-center pb-2">
          <h6 className="font-bold">Rating</h6>
          <div className="flex items-center">
            {stars(1, rating, true)}
            {stars(2, rating, true)}
            {stars(3, rating, true)}
            {stars(4, rating, true)}
            {stars(5, rating, true)}
          </div>
          <h6 className="font-bold">{post.stats.rating.toFixed(1)}</h6>
        </div>
        <div className="flex flex-wrap justify-center items-center">
          {/* Plot */}
          <div className="flex flex-col items-center px-3 pt-1">
            <h6 className="text-sm text-gray-500">Plot</h6>
            <div className="flex items-center">
              {stars(1, plot, false)}
              {stars(2, plot, false)}
              {stars(3, plot, false)}
              {stars(4, plot, false)}
              {stars(5, plot, false)}
            </div>
          </div>
          {/* Writing */}
          <div className="flex flex-col items-center px-3 pt-1">
            <h6 className="text-sm text-gray-500">Writing</h6>
            <div className="flex items-center">
              {stars(1, writing, false)}
              {stars(2, writing, false)}
              {stars(3, writing, false)}
              {stars(4, writing, false)}
              {stars(5, writing, false)}
            </div>
          </div>
          {/* Characters */}
          <div className="flex flex-col items-center px-3 pt-1">
            <h6 className="text-sm text-gray-500">Characters</h6>
            <div className="flex items-center">
              {stars(1, characters, false)}
              {stars(2, characters, false)}
              {stars(3, characters, false)}
              {stars(4, characters, false)}
              {stars(5, characters, false)}
            </div>
          </div>

          {/* Primary Genre */}
          <div className="flex flex-col items-center px-3 pt-1">
            <h6 className="text-sm text-gray-500">{post.primaryGenre} Value</h6>
            <div className="flex items-center">
              {stars(1, primaryGenre, false)}
              {stars(2, primaryGenre, false)}
              {stars(3, primaryGenre, false)}
              {stars(4, primaryGenre, false)}
              {stars(5, primaryGenre, false)}
            </div>
          </div>

          {/* Secondary Genre */}
          {post.secondaryGenre !== "None" && (
            <div className="flex flex-col items-center px-3 pt-1">
              <h6 className="text-sm text-gray-500">
                {post.secondaryGenre} Value
              </h6>
              <div className="flex items-center">
                {stars(1, secondaryGenre, false)}
                {stars(2, secondaryGenre, false)}
                {stars(3, secondaryGenre, false)}
                {stars(4, secondaryGenre, false)}
                {stars(5, secondaryGenre, false)}
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-center items-center flex-wrap pt-4">
          {exists(post.stats.keptReading) ? (
            <h6 className="px-2 text-gray-500">
              <span className="font-semibold text-black text-lg">
                {percent(post.stats.keptReading)}%
              </span>{" "}
              kept reading
            </h6>
          ) : (
            <h6 className="px-2 text-gray-500 font-semibold">
              Be the first to review this post!
            </h6>
          )}

          {exists(post.stats.wouldRecommend) && (
            <h6 className="px-2 text-gray-500">
              <span className="font-semibold text-black text-lg">
                {percent(post.stats.wouldRecommend)}%
              </span>{" "}
              would recommend
            </h6>
          )}

          {exists(post.stats.wantAdapted) && (
            <h6 className="px-2 text-gray-500">
              <span className="font-semibold text-black text-lg">
                {percent(post.stats.wantAdapted)}%
              </span>{" "}
              want a {post.postType === "Prose" ? "book" : "film"}
            </h6>
          )}
        </div>
      </div>
    </>
  );
};

export default PostDetails;
