import { Helmet } from "react-helmet";

function Forum() {
  return (
    <main>
      <Helmet>
        <title>ScriptOwl | Forgot Password</title>
      </Helmet>
      <h1 className="page-title">Forum</h1>
    </main>
  );
}

export default Forum;
