import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../../firebase.config";
import Review from "../../types/Review";
import docToReview from "./../convert/docToReview";

const fetchReviews = async (postId: string) => {
  try {
    // Get reference
    const reviewsRef = collection(db, `posts/${postId}/reviews`);

    // Create a query
    const q = query(reviewsRef, orderBy("stats.wasHelpful", "desc"));

    // Execute query
    const querySnap = await getDocs(q);

    const reviews: Array<Review> = [];

    querySnap.forEach((doc) => {
      return reviews.push(docToReview(doc));
    });

    return reviews;
  } catch (error) {
    console.log(error);
  }
};

export default fetchReviews;
