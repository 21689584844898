import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import Review from "../../types/Review";

const docToReview = (doc: QueryDocumentSnapshot<DocumentData>): Review => {
  return {
    id: doc.id,
    title: doc.data().title,
    description: doc.data().description,
    detailed: doc.data().detailed,
    userId: doc.data().userId,
    userName: doc.data().userName,
    stats: doc.data().stats,
    timeStamp: doc.data().timeStamp.toDate(),
    rating: doc.data().rating,
    ratingDetails: doc.data().ratingDetails,
    keptReading: doc.data().keptReading,
    wouldRecommend: doc.data().wouldRecommend,
    wantAdapted: doc.data().wantAdapted,
  };
};

export default docToReview;
