import Post from "../types/Post";
import { useState } from "react";
import {
  MdStar,
  MdStarHalf,
  MdStarBorder,
  MdBookmark,
  MdBookmarkBorder,
} from "react-icons/md";
import toast from "react-hot-toast";
import reviewText from "../lib/tools/reviewText";

interface IProps {
  post: Post;
}

function CardDetails({ post }: IProps) {
  const [saved, setSaved] = useState(false);

  const handleSave = () => {
    toast.success(saved ? "Unsaved!" : "Saved!");
    setSaved((prev) => !prev);
  };

  const exists = (value: number): boolean => {
    return post.stats.reviewCount > 0 && value > 0;
  };

  const percent = (value: number): string => {
    return Math.floor((value / post.stats.reviewCount) * 100).toString();
  };

  const getRating = (level: number) => {
    if (post.stats.rating && post.stats.rating >= level)
      return <MdStar size="30" />;
    else {
      if (post.stats.rating && post.stats.rating + 0.5 >= level)
        return <MdStarHalf size="30" />;
      else return <MdStarBorder size="30" />;
    }
  };

  return (
    <>
      <div className="flex justify-between items-start">
        <div>
          {/* Stars */}
          <div
            className={`${
              post.stats.rating > 0 ? "text-yellow-400" : "text-gray-400"
            } flex`}
          >
            {getRating(1)}
            {getRating(2)}
            {getRating(3)}
            {getRating(4)}
            {getRating(5)}
          </div>

          {/* Review Count */}
          <p className="text-sm text-gray-400 pt-1 m-0">{reviewText(post)}</p>
        </div>

        {/* Save Button */}
        <button
          className="text-primary hover:text-primary-light transition duration-200"
          onClick={handleSave}
        >
          {saved ? <MdBookmark size="35" /> : <MdBookmarkBorder size="35" />}
        </button>
      </div>
      <div className="py-4">
        {exists(post.stats.keptReading) ? (
          <h6 className="mb-1">
            <span className="font-semibold text-lg">
              {percent(post.stats.keptReading)}%
            </span>{" "}
            kept reading
          </h6>
        ) : (
          <h6 className="mb-1 font-semibold">
            Be the first to read this post!
          </h6>
        )}

        {exists(post.stats.wouldRecommend) && (
          <h6 className="mb-1">
            <span className="font-semibold text-lg">
              {percent(post.stats.wouldRecommend)}%
            </span>{" "}
            would recommend
          </h6>
        )}

        {exists(post.stats.wantAdapted) && (
          <h6 className="mb-1">
            <span className="font-semibold text-lg">
              {percent(post.stats.wantAdapted)}%
            </span>{" "}
            want a {post.postType === "Prose" ? "book" : "film"}
          </h6>
        )}
      </div>
    </>
  );
}

export default CardDetails;
