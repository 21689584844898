import Post from "../types/Post";
import checkDateDifference from "../lib/checkDateDifference";

type Props = {
  post: Post;
};

const CardBadge = ({ post }: Props) => {
  const getText = () => {
    if (checkDateDifference(post.lastUpdated, post.timeStamp, 2)) {
      return "New!";
    } else return "Updated!";
  };

  return (
    <>
      {checkDateDifference(new Date(Date.now()), post.lastUpdated, 2) ? (
        <div className="card-badge">{getText()}</div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CardBadge;
