import { Helmet } from "react-helmet";
import Loader from "../components/Loader";

function Popular() {
  return (
    <main>
      <Helmet>
        <title>ScriptOwl | Popular</title>
      </Helmet>
      <h1 className="page-title">Popular</h1>
      <Loader />
    </main>
  );
}

export default Popular;
