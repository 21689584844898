import React from "react";

interface Props {
  func: Function;
}

function LoadMore({ func }: Props) {
  return (
    <div className="flex justify-center">
      <button className="btn-primary-md" onClick={() => func()}>
        Load more
      </button>
    </div>
  );
}

export default LoadMore;
