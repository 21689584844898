const getTagArray = () => {
  return [
    "Adventure",
    "Alternate History",
    "Animation",
    "Biographical",
    "Children",
    "Cyberpunk",
    "Dance",
    "Dark Comedy",
    "Dark Fantasy",
    "Disaster",
    "Dystopian",
    "Epic",
    "Erotic",
    "Experimental",
    "Family",
    "Found Footage",
    "Gangster",
    "Gothic",
    "History",
    "Legal Drama",
    "Martial Arts",
    "Medical",
    "Military",
    "Music",
    "Musical",
    "Mystery",
    "Noir",
    "Police",
    "Political Drama",
    "Post-Apocalypse",
    "Psychological Drama",
    "Psychological Horror",
    "Psychological Thriller",
    "Silent",
    "Space",
    "Sports",
    "Spy",
    "Steampunk",
    "Superhero",
    "Supernatural",
    "Surreal",
    "Teen",
    "Urban Fantasy",
    "War",
    "Western",
    "Young Adult",
  ];
};

export default getTagArray;
