import { useState } from "react";
import { MdStar, MdStarBorder, MdStarHalf } from "react-icons/md";

interface Props {
  initialValue: number;
  title: string;
  adjust: Function;
}

function RatingStars({ initialValue, title, adjust }: Props) {
  const [rating, setRating] = useState(initialValue);

  const handleClick = (value: number) => {
    setRating(value);
    adjust(value);
  };

  const getRating = (level: number) => {
    if (rating && rating >= level)
      return (
        <div className="relative">
          <button
            type="button"
            onClick={() => handleClick(level - 0.5)}
            className="absolute top-0 left-0 w-1/2 h-full"
          ></button>
          <button
            type="button"
            onClick={() => handleClick(level)}
            className="absolute top-0 right-0 w-1/2 h-full"
          ></button>
          <div className="text-yellow-400">
            <MdStar size="30" />
          </div>
        </div>
      );
    else {
      if (rating && rating + 0.5 >= level)
        return (
          <div className="relative">
            <button
              type="button"
              onClick={() => handleClick(level - 0.5)}
              className="absolute top-0 left-0 w-1/2 h-full"
            ></button>
            <button
              type="button"
              onClick={() => handleClick(level)}
              className="absolute top-0 right-0 w-1/2 h-full"
            ></button>
            <div className="text-yellow-400">
              <MdStarHalf size="30" />
            </div>
          </div>
        );
      else
        return (
          <div className="relative">
            <button
              type="button"
              onClick={() => handleClick(level - 0.5)}
              className="absolute top-0 left-0 w-1/2 h-full"
            ></button>
            <button
              type="button"
              onClick={() => handleClick(level)}
              className="absolute top-0 right-0 w-1/2 h-full"
            ></button>
            <div className="text-yellow-400">
              <MdStarBorder size="30" />
            </div>
          </div>
        );
    }
  };

  return (
    <div className="flex justify-between items-center">
      <h6 className="font-bold">{title}</h6>
      <div className="flex items-center">
        {getRating(1)}
        {getRating(2)}
        {getRating(3)}
        {getRating(4)}
        {getRating(5)}
      </div>
    </div>
  );
}

export default RatingStars;
