import { useState } from "react";
import Review from "../../types/Review";
import { MdStar, MdStarBorder, MdStarHalf } from "react-icons/md";
import { auth, db } from "../../firebase.config";
import { doc, updateDoc } from "firebase/firestore";
import ReviewDetails from "./ReviewDetails";

interface IProps {
  review: Review;
  postId: string;
  primaryGenre: string;
  secondaryGenre: string;
}

const ReviewCard = ({
  review,
  postId,
  primaryGenre,
  secondaryGenre,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const getPercentHelpful = () => {
    return `(${Math.floor(
      (review.stats.wasHelpful / review.stats.totalResponses) * 100
    )}%)`;
  };

  const stars = (level: number) => {
    if (review.rating >= level)
      return (
        <div className="text-yellow-400">
          <MdStar size="25" />
        </div>
      );
    else {
      if (review.rating + 0.5 >= level)
        return (
          <div className="text-yellow-400">
            <MdStarHalf size="25" />
          </div>
        );
      else
        return (
          <div className="text-yellow-400">
            <MdStarBorder size="25" />
          </div>
        );
    }
  };

  const respond = async (wasHelpful: boolean) => {
    setLoading(true);

    const data = {
      ...review,
      stats: {
        bannedUsers: [...review.stats.bannedUsers, auth.currentUser?.uid],
        totalResponses: review.stats.totalResponses + 1,
        wasHelpful: review.stats.wasHelpful + (wasHelpful ? 1 : 0),
      },
    };

    const reviewRef = doc(db, "posts", postId, "reviews", review.id);
    await updateDoc(reviewRef, data);

    if (auth.currentUser) review.stats.bannedUsers.push(auth.currentUser.uid);
    review.stats.totalResponses += 1;
    review.stats.wasHelpful += wasHelpful ? 1 : 0;

    setLoading(false);
  };

  return (
    <div className="rounded-lg bg-white shadow-md p-4 mb-8">
      <div className="flex justify-between items-start mb-2">
        <div>
          <h6 className="font-bold">{review.title}</h6>
          <h6 className="text-sm text-gray-400">
            From <span className="text-primary">{review.userName}</span>
          </h6>
        </div>
        <div className="flex flex-col items-center">
          <div className="flex flex-shrink-0 items-center">
            {stars(1)}
            {stars(2)}
            {stars(3)}
            {stars(4)}
            {stars(5)}
          </div>
          <h6 className="text-gray-500 font-bold">
            {review.rating.toFixed(1)}
          </h6>
        </div>
      </div>
      {review.detailed && (
        <ReviewDetails
          review={review}
          primaryGenre={primaryGenre}
          secondaryGenre={secondaryGenre}
        />
      )}
      <p className="mb-2">{review.description}</p>
      <div>
        {review.stats.totalResponses > 0 && (
          <h6 className="text-sm text-gray-400">
            {review.stats.wasHelpful} of {review.stats.totalResponses} liked
            this review {getPercentHelpful()}.
          </h6>
        )}
        {auth.currentUser?.uid &&
          !review.stats.bannedUsers.includes(auth.currentUser.uid) && (
            <div className="flex items-center">
              {loading ? (
                <h6 className="text-sm text-gray-400">Sending response...</h6>
              ) : (
                <>
                  <h6 className="text-sm text-gray-400">
                    Did you like this review?
                  </h6>
                  <button
                    onClick={() => respond(true)}
                    className="text-primary hover:text-primary-light transition duration-200 ml-3"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => respond(false)}
                    className="text-primary hover:text-primary-light transition duration-200 ml-3"
                  >
                    No
                  </button>
                </>
              )}
            </div>
          )}
      </div>
    </div>
  );
};

export default ReviewCard;
