import { useState } from "react";

interface Props {
  initialValue: boolean;
  title: string;
  adjust: Function;
}

function YesNoButton({ initialValue, title, adjust }: Props) {
  const [bool, setBool] = useState(initialValue);

  const handleClick = (value: boolean) => {
    setBool(value);
    adjust(value);
  };

  return (
    <div className="flex justify-between items-center pt-1">
      <h6>{title}</h6>
      <div className="flex items-center">
        <button
          type="button"
          onClick={() => handleClick(false)}
          className={`rounded-lg px-2 py-1 text-white text-sm mr-2
      ${
        !bool
          ? "bg-primary"
          : "bg-gray-400 hover:bg-gray-300 transition duration-200"
      }`}
        >
          No
        </button>
        <button
          type="button"
          onClick={() => handleClick(true)}
          className={`rounded-lg px-2 py-1 text-white text-sm
      ${
        bool
          ? "bg-primary"
          : "bg-gray-400 hover:bg-gray-300 transition duration-200"
      }`}
        >
          Yes
        </button>
      </div>
    </div>
  );
}

export default YesNoButton;
