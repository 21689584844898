import { useState, useEffect } from "react";

// Libraries
import fetchMostRecentPosts from "../lib/fetch/fetchMostRecentPosts";

// Components
import Loader from "../components/Loader";
import Post from "../types/Post";
import toast from "react-hot-toast";
import Card from "../components/Card";
import { Helmet } from "react-helmet";
import fetchMorePosts from "../lib/fetch/fetchMorePosts";
import LoadMore from "../components/LoadMore";

function Home() {
  const [posts, setPosts] = useState<Array<Post>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);

    fetchMostRecentPosts().then((res) => {
      if (res) {
        setPosts(res);
      } else {
        toast.error("Could not retrieve documents!");
      }
    });

    setLoading(false);
  }, []);

  const handleLoadMoreClicked = () => {
    setLoading(true);

    fetchMorePosts(posts[posts.length - 1].timeStamp).then((res) => {
      if (res) {
        console.log(res);
        const array = posts.concat(res);
        setPosts(array);
      } else {
        toast.error("Could not retrieve documents!");
      }
    });

    setLoading(false);
  };

  return (
    <main>
      <Helmet>
        <title>ScriptOwl | Home</title>
      </Helmet>
      <h1 className="page-title">Home</h1>
      {loading && !posts ? (
        <Loader />
      ) : (
        <>
          {posts.map((post) => (
            <Card key={post.id} post={post} />
          ))}
          <LoadMore func={handleLoadMoreClicked} />
        </>
      )}
    </main>
  );
}

export default Home;
