import { Routes, Route } from "react-router-dom";

// Components
import PrivateRoute from "./PrivateRoute";
import { Toaster } from "react-hot-toast";
import Home from "../pages/Home";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import Profile from "../pages/Profile";
import ForgotPassword from "../pages/ForgotPassword";
import Categories from "../pages/Categories";
import Popular from "../pages/Popular";
import Forum from "../pages/Forum";
import Post from "../pages/Post";
import CreatePost from "../pages/CreatePost";
import CreatePostReview from "../pages/CreatePostReview";

interface Props {
  useSidebar: boolean;
}

function PageRoutes({ useSidebar }: Props) {
  const getClass = () => {
    if (useSidebar) {
      return "ml-72 px-4 py-6";
    } else return "mt-12 px-4 py-6";
  };

  return (
    <>
      <div className={getClass()}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/popular" element={<Popular />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/forum" element={<Forum />} />

          <Route path="/post/:postId" element={<Post />} />
          <Route path="/review/:postId/" element={<CreatePostReview />} />

          <Route path="/profile" element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path="/create" element={<PrivateRoute />}>
            <Route path="/create" element={<CreatePost />} />
          </Route>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
      </div>
      <Toaster />
    </>
  );
}

export default PageRoutes;
