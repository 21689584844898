import Post from "../types/Post";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import fetchSinglePost from "../lib/fetch/fetchSinglePost";
import { Helmet } from "react-helmet";
import { Document, Page, pdfjs } from "react-pdf";
import Reviews from "../components/reviews/Reviews";
import PostDetails from "../components/post/PostDetails";

function PostPage() {
  const [post, setPost] = useState<Post | null>(null);
  const [loading, setLoading] = useState(true);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    setLoading(true);
    console.log("Render");
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    if (params.postId) {
      fetchSinglePost(params.postId.toString()).then((res) => {
        setPost(res);
      });
    }
    setLoading(false);
  }, [navigate, params.postId]);

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <main>
      {!loading && post ? (
        <>
          {/* Main document */}
          <div className="pb-16">
            <Helmet>
              <title>ScriptOwl | {post.title}</title>
            </Helmet>
            <div className="w-full pb-2">
              <h1 className="text-center text-lg font-semibold">
                {post.title}
              </h1>
            </div>
            <div
              className="relative"
              onContextMenu={() => "return false"}
              onMouseDown={() => "return false"}
              onCopy={() => "return false"}
              onCut={() => "return false"}
              onDrag={() => "return false"}
            >
              <Document
                file={post.storageUrl}
                onLoadSuccess={(docProxy) => {
                  setNumPages(docProxy.numPages);
                  setPageNumber(1);
                }}
              >
                <Page pageNumber={pageNumber} />
              </Document>

              {/* Page controls */}
              <div className="page-controls w-full flex justify-center items-center">
                <button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  className={
                    pageNumber <= 1
                      ? "control-btn-disabled rounded-l-md"
                      : "control-btn rounded-l-md"
                  }
                >
                  {"<"}
                </button>
                <span className="bg-gray-200 text-gray-600 h-8 px-2 pt-1">
                  Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                  {numPages || "--"}
                </span>
                <button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                  className={
                    pageNumber >= numPages
                      ? "control-btn-disabled rounded-r-md"
                      : "control-btn rounded-r-md"
                  }
                >
                  {">"}
                </button>
              </div>
              <div
                className="absolute top-0 left-0 w-full h-full z-10"
                onContextMenu={() => "return false"}
                onMouseDown={() => "return false"}
                onCopy={() => "return false"}
                onCut={() => "return false"}
                onDrag={() => "return false"}
              ></div>
            </div>
          </div>
          <PostDetails post={post} />
          <Reviews post={post} />
        </>
      ) : (
        <Loader />
      )}
    </main>
  );
}

export default PostPage;
