import Media from "react-media";
import { BrowserRouter as Router } from "react-router-dom";

// Components
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import PageRoutes from "./components/PageRoutes";

function App() {
  return (
    <Router>
      <Media
        queries={{
          small: "(max-width: 767px)",
          medium: "(min-width: 768px)",
        }}
      >
        {(matches) => (
          <>
            {matches.small && (
              <>
                <Navbar />
                <PageRoutes useSidebar={false} />
              </>
            )}
            {matches.medium && (
              <>
                <Sidebar />
                <PageRoutes useSidebar={true} />
              </>
            )}
          </>
        )}
      </Media>
    </Router>
  );
}

export default App;
