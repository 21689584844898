import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase.config";

const signUpUser = async (email: string, password: string) => {
  const userCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password
  );

  return userCredential.user;
};

export default signUpUser;
