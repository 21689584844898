import { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../firebase.config";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";

function ForgotPassword() {
  const [email, setEmail] = useState<string>("");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Email sent!");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  return (
    <main className="max-w-md mx-auto">
      <Helmet>
        <title>ScriptOwl | Forgot Password</title>
      </Helmet>
      <h1 className="page-title">Forgot Password</h1>
      <form onSubmit={onSubmit} className="flex flex-col">
        <input
          type="email"
          className="form-input"
          placeholder="Email"
          id="email"
          value={email}
          onChange={onChange}
        />
        <button type="submit" className="btn-primary-md mt-4">
          Send Reset Link
        </button>
      </form>

      <Link
        to="/sign-in"
        className="mt-4 text-primary font-bold flex justify-center"
      >
        Sign In Instead
      </Link>
    </main>
  );
}

export default ForgotPassword;
