import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { auth } from "../firebase.config";
import { v4 as uuidv4 } from "uuid";

const createFileUrl = async (file: File) => {
  if (auth.currentUser) {
    const storage = getStorage();
    const fileName = `${uuidv4()}-${auth.currentUser.uid}`;

    const storageRef = ref(storage, "files/" + fileName);

    const snapshot = await uploadBytes(storageRef, file);

    return await getDownloadURL(snapshot.ref);
  } else return null;
};

export default createFileUrl;
