import { Helmet } from "react-helmet";

function Categories() {
  return (
    <main>
      <Helmet>
        <title>ScriptOwl | Categories</title>
      </Helmet>
      <h1 className="page-title">Categories</h1>
    </main>
  );
}

export default Categories;
