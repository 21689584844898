import Post from "../types/Post";
import { MdArrowRightAlt, MdOutlineMoreVert } from "react-icons/md";
import CardBadge from "./CardBadge";
import { Link } from "react-router-dom";
import CardDetails from "./CardDetails";

interface IProps {
  post: Post;
}

const CardMobile = ({ post }: IProps) => {
  return (
    <div className="relative rounded-xl max-w-3xl shadow-lg mx-auto mb-16">
      <CardBadge post={post} />
      <div className="flex flex-col bg-white rounded-lg">
        <div className="p-4 flex flex-col">
          {/* Top Portion */}
          <div>
            {/* Title & Genres */}
            <div className="flex justify-between items-center">
              <h6 className="text-xl font-semibold">{post.title}</h6>
              <div className="flex items-center">
                <span className="px-2 py-1 text-sm text-white bg-primary rounded-lg">
                  {post.primaryGenre}
                </span>
                {post.secondaryGenre !== "None" && (
                  <span className="px-2 py-1 text-sm text-white bg-gray-400 rounded-lg ml-2">
                    {post.secondaryGenre}
                  </span>
                )}
              </div>
            </div>

            {/* Author */}
            <p className="text-sm text-gray-400 pb-0">
              By
              <span className="text-primary ml-1">{post.userName}</span>
            </p>

            {/* Subtitle */}
            <p className="text-sm text-gray-400 pb-2">
              {post.postType === "Prose" ? "Prose" : "Screenplay"} |{" "}
              {post.postType === "Prose"
                ? post.stats.wordCount.toLocaleString() + " words"
                : post.stats.pageCount + " pages"}
            </p>

            {/* Description */}
            <p>{post.description}</p>
          </div>

          {/* Tags */}
          <div className="flex flex-wrap items-center pt-2 mt-8 border-t">
            {post.tags.map((tag) => (
              <button key={tag} className="tag">
                <span className="font-semibold mr-1">#</span>
                {tag.toLowerCase()}
              </button>
            ))}
          </div>
        </div>

        {/* Bottom Portion */}
        <div className="bg-gray-100 rounded-b-lg flex-shrink-0 p-4 flex flex-col justify-center">
          <CardDetails post={post} />
          <div className="flex justify-between items-center">
            <Link to={`/post/${post.id}`}>
              <button
                className="flex justify-between items-center px-4 py-1 rounded-xl text-white bg-primary
            hover:bg-primary-light transition duration-200"
              >
                Go to this post
                <MdArrowRightAlt size="25" />
              </button>
            </Link>
            <button className="text-gray-400 hover:text-gray-300 transition duration-200">
              <MdOutlineMoreVert size="35" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardMobile;
