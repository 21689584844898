import Media from "react-media";

function Loader() {
  return (
    <Media
      queries={{
        small: "(max-width: 767px)",
        medium: "(min-width: 768px)",
      }}
    >
      {(matches) => (
        <>
          {matches.small && <div className="loader loader-mobile"></div>}
          {matches.medium && <div className="loader loader-desktop"></div>}
        </>
      )}
    </Media>
  );
}

export default Loader;
