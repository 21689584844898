import React, { useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { MdRemoveRedEye } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import signInUser from "../lib/signInUser";

type FormData = {
  email: string;
  password: string;
};

function SignIn() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const navigate = useNavigate();

  const validInput = (): boolean => {
    if (password.length < 6) {
      toast.error("Password must be 6 characters or more");
      return false;
    }
    return true;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validInput()) {
      setLoading(true);
      try {
        const user = await signInUser(email, password);
        if (user) {
          toast.success("Logged in!");
          navigate("/");
        }
      } catch (error) {
        toast.error("Something went wrong!");
        console.log(error);
      }
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <main className="max-w-md mx-auto">
          <Helmet>
            <title>ScriptOwl | Sign In</title>
          </Helmet>
          <h1 className="page-title">Welcome Back!</h1>
          <form onSubmit={onSubmit} className="flex flex-col">
            <input
              type="email"
              className="form-input"
              placeholder="Email"
              id="email"
              value={email}
              onChange={onChange}
            />
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                className="form-input"
                placeholder="Password"
                id="password"
                value={password}
                onChange={onChange}
              />
              <button
                type="button"
                className="top-2 right-2 absolute"
                onClick={() => setShowPassword((prevState) => !prevState)}
              >
                <MdRemoveRedEye className="text-2xl" />
              </button>
            </div>

            <button type="submit" className="btn-primary-md mt-4">
              Sign In
            </button>
          </form>

          <Link
            to="/sign-up"
            className="mt-4 text-primary font-bold flex justify-center"
          >
            Sign Up Instead
          </Link>
          <Link
            to="/forgot-password"
            className="text-gray-400 text-sm flex justify-center mt-4"
          >
            Forgot your password?
          </Link>
        </main>
      )}
    </>
  );
}

export default SignIn;
