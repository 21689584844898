import { useState, useEffect } from "react";
import fetchReviews from "../../lib/fetch/fetchReviews";
import { Link } from "react-router-dom";
import Review from "../../types/Review";
import Loader from "../Loader";
import ReviewCard from "./ReviewCard";
import { MdCreate } from "react-icons/md";
import { auth } from "../../firebase.config";
import Post from "../../types/Post";

interface IProps {
  post: Post;
}

type Pagination = Array<Review[]>;

const Reviews = ({ post }: IProps) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [numReviews, setNumReviews] = useState(0);
  const [pagination, setPagination] = useState<Pagination>([]);

  const reviewsPerPage = 5;

  useEffect(() => {
    setLoading(true);
    fetchReviews(post.id).then((res) => {
      if (res) {
        setPage(0);
        setNumReviews(res.length);
        setPagination(paginate(res));
      }
    });
    setLoading(false);
  }, [post.id]);

  const paginate = (reviews: Review[]): Pagination => {
    let array: Pagination = [];
    for (let i = 0; i < reviews.length; i++) {
      const review = reviews[i];
      if (i % reviewsPerPage === 0) {
        array.push([]);
      }
      array[array.length - 1].push(review);
    }
    console.log(array);
    return array;
  };

  const changePage = (increment: boolean) => {
    setPage(increment ? page + 1 : page - 1);
  };

  return (
    <div className="py-8">
      <h6 className="font-bold text-center mb-4 pb-2 border-b border-b-gray-300 mx-auto max-w-xl">
        Reviews
      </h6>
      {loading ? (
        <Loader />
      ) : (
        <>
          {pagination.length > 0 ? (
            <div className="container mx-auto max-w-xl">
              <div className="flex justify-between items-center mb-4">
                <h6 className="text-gray-500">
                  Showing{" "}
                  {pagination[page].indexOf(pagination[page][0]) +
                    reviewsPerPage * page +
                    1}
                  -
                  {pagination[page].indexOf(
                    pagination[page][pagination[page].length - 1]
                  ) +
                    reviewsPerPage * page +
                    1}{" "}
                  of {numReviews} reviews
                </h6>

                {/* Review Button */}
                {auth.currentUser &&
                  !post.stats.bannedUsers.includes(auth.currentUser.uid) && (
                    <div className="flex-shrink-0">
                      <Link to={`/review/${post.id}`}>
                        <button
                          className="flex justify-between items-center px-4 py-1 rounded-xl text-white bg-primary 
      hover:bg-primary-light transition duration-200"
                        >
                          Leave a review
                          <MdCreate size="25" className="ml-2" />
                        </button>
                      </Link>
                    </div>
                  )}
              </div>
              {pagination[page].map((review) => (
                <ReviewCard
                  key={review.id}
                  review={review}
                  postId={post.id}
                  primaryGenre={post.primaryGenre}
                  secondaryGenre={post.secondaryGenre}
                />
              ))}

              {/* Pagination controls */}
              <div className="flex justify-center items-center">
                <button
                  type="button"
                  disabled={page <= 0}
                  onClick={() => changePage(false)}
                  className={
                    page <= 0
                      ? "control-btn-disabled rounded-l-md"
                      : "control-btn rounded-l-md"
                  }
                >
                  {"<"}
                </button>
                {pagination.map((review) => (
                  <button
                    disabled={page === pagination.indexOf(review)}
                    onClick={() => setPage(pagination.indexOf(review))}
                    className={
                      page === pagination.indexOf(review)
                        ? "control-num-disabled"
                        : "control-num"
                    }
                  >
                    {pagination.indexOf(review) + 1}
                  </button>
                ))}
                <button
                  type="button"
                  disabled={page === pagination.length - 1}
                  onClick={() => changePage(true)}
                  className={
                    page >= pagination.length - 1
                      ? "control-btn-disabled rounded-r-md"
                      : "control-btn rounded-r-md"
                  }
                >
                  {">"}
                </button>
              </div>
            </div>
          ) : (
            <h6>No reviews to display!</h6>
          )}
        </>
      )}
    </div>
  );
};

export default Reviews;
