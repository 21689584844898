const checkDateDifference = (
  dateOne: Date,
  dateTwo: Date,
  difference: number
): boolean => {
  var millisecondsPerDay = 1000 * 60 * 60 * 24;
  var millisBetween = dateOne.getTime() - dateTwo.getTime();
  var days = millisBetween / millisecondsPerDay;

  return Math.floor(days) < difference;
};

export default checkDateDifference;
