import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import { Helmet } from "react-helmet";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { auth, db } from "../firebase.config";
import createFileUrl from "../lib/createFileUrl";
import getTagArray from "../lib/getTagArray";

type FormData = {
  title: string;
  description: string;
  primaryGenre: string;
  secondaryGenre: string;
  postType: string;
  wordCount: number;
  pageCount: number;
  tags: Array<string>;
  file: File | null | undefined;
};

function CreatePost() {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    title: "",
    description: "",
    primaryGenre: "Action",
    secondaryGenre: "None",
    postType: "Prose",
    wordCount: 0,
    pageCount: 0,
    tags: [],
    file: null,
  });

  const navigate = useNavigate();

  const { title, description, wordCount, pageCount, tags } = formData;

  const validInput = (): boolean => {
    if (formData.title === "") {
      toast.error("Please enter a title.");
      return false;
    }
    if (formData.description === "") {
      toast.error("Please enter a description.");
      return false;
    }
    if (formData.primaryGenre === "") {
      toast.error("Please enter a primary genre.");
      return false;
    }
    if (formData.secondaryGenre === "") {
      toast.error("Please enter a secondary genre.");
      return false;
    }
    if (formData.wordCount === 0) {
      toast.error("Please enter a word count.");
      return false;
    }
    if (formData.pageCount === 0) {
      toast.error("Please enter a page count.");
      return false;
    }
    if (formData.tags === []) {
      toast.error("Please add at least one tag.");
      return false;
    }
    if (!formData.file) {
      toast.error("Please add a file");
      return false;
    }
    return true;
  };

  const handleSelect = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value);
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleAddTag = (tag: string) => {
    if (formData.tags.length < 3) {
      setFormData({
        ...formData,
        tags: [...tags, tag],
      });
    } else toast.error("3 tags only!");
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (validInput() && formData.file) {
      setLoading(true);
      try {
        const url = await createFileUrl(formData.file);
        const data = {
          title: formData.title,
          description: formData.description,
          postType: formData.postType,
          primaryGenre: formData.primaryGenre,
          secondaryGenre: formData.secondaryGenre,
          tags: formData.tags,
          storageUrl: url,
          userId: auth.currentUser?.uid,
          userName: auth.currentUser?.displayName,
          stats: {
            wordCount: formData.wordCount,
            pageCount: formData.pageCount,
            bannedUsers: [auth.currentUser?.uid],
            reviewCount: 0,
            rating: 0,
            ratingValues: [],
            ratingDetails: {
              plot: [],
              writing: [],
              characters: [],
              primaryGenre: [],
              secondaryGenre: [],
            },
            wouldRecommend: 0,
            keptReading: 0,
            wantAdapted: 0,
          },
          timeStamp: new Date(Date.now()),
          lastUpdated: new Date(Date.now()),
        };

        const docRef = await addDoc(collection(db, "posts"), data);
        setLoading(false);
        toast.success("Post created!");
        navigate(`/post/${docRef.id}`);
      } catch (error) {
        toast.error("Something went wrong!");
        console.log(error);
      }
      setLoading(false);
    }
  };

  const onMutate = (e: React.ChangeEvent<HTMLInputElement>) => {
    let bool: boolean;

    if (e.target.value === "true") {
      bool = true;
    }
    if (e.target.value === "false") {
      bool = false;
    }

    // Files
    if (e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        file: e.target.files?.item(0),
      }));
    }

    // Text/Booleans/Numbers
    if (!e.target.files) {
      setFormData((prevState) => ({
        ...prevState,
        [e.target.id]: bool ?? e.target.value,
      }));
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <main className="max-w-md mx-auto">
          <Helmet>
            <title>ScriptOwl | Create a Post</title>
          </Helmet>
          <h1 className="page-title">Create a Post</h1>
          <form onSubmit={onSubmit} className="flex flex-col">
            <label htmlFor="title" className="font-semibold pb-1">
              Title
            </label>
            <input
              type="text"
              className="form-input"
              placeholder="Title"
              id="title"
              value={title}
              onChange={onMutate}
              autoComplete="off"
            />

            <label htmlFor="description" className="font-semibold pb-1">
              Description
            </label>
            <textarea
              className="form-input"
              placeholder="Description"
              id="description"
              value={description}
              rows={4}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              autoComplete="off"
            />

            <label htmlFor="postType" className="font-semibold pb-1">
              Post Type
            </label>
            <select
              id="postType"
              onChange={handleSelect}
              className="form-input"
              placeholder="Please select a post type"
            >
              <option value="Prose">Prose</option>
              <option value="Screenplay">Screenplay</option>
            </select>

            <label htmlFor="primaryGenre" className="font-semibold pb-1">
              Primary Genre
            </label>
            <select
              id="primaryGenre"
              onChange={handleSelect}
              className="form-input"
              placeholder="Please select a primary genre"
            >
              <option value="Action">Action</option>
              <option value="Comedy">Comedy</option>
              <option value="Crime">Crime</option>
              <option value="Drama">Drama</option>
              <option value="Fantasy">Fantasy</option>
              <option value="Horror">Horror</option>
              <option value="Romance">Romance</option>
              <option value="Sci-Fi">Sci-Fi</option>
              <option value="Thriller">Thriller</option>
            </select>

            <label htmlFor="secondaryGenre" className="font-semibold pb-1">
              Secondary Genre
              <span className="text-sm font-normal ml-2 text-gray-400">
                (Optional)
              </span>
            </label>
            <select
              id="secondaryGenre"
              onChange={handleSelect}
              className="form-input"
              placeholder="(Optional) select a primary genre"
            >
              <option value="None">None</option>
              <option value="Action">Action</option>
              <option value="Comedy">Comedy</option>
              <option value="Crime">Crime</option>
              <option value="Drama">Drama</option>
              <option value="Fantasy">Fantasy</option>
              <option value="Horror">Horror</option>
              <option value="Romance">Romance</option>
              <option value="Sci-Fi">Sci-Fi</option>
              <option value="Thriller">Thriller</option>
            </select>

            <label htmlFor="wordCount" className="font-semibold pb-1">
              Word Count
            </label>
            <input
              type="number"
              className="form-input"
              placeholder="Description"
              id="wordCount"
              value={wordCount}
              onChange={onMutate}
              autoComplete="off"
            />

            <label htmlFor="pageCount" className="font-semibold pb-1">
              Page Count
            </label>
            <input
              type="number"
              className="form-input"
              placeholder="Page Count"
              id="pageCount"
              value={pageCount}
              onChange={onMutate}
              autoComplete="off"
            />

            <label htmlFor="pageCount" className="font-semibold pb-1">
              File
              <span className="text-sm font-normal ml-2 text-gray-400">
                (Must be in PDF format)
              </span>
            </label>
            <input
              className=""
              type="file"
              id="file"
              onChange={onMutate}
              accept=".pdf"
              required
            />

            <label className="font-semibold pb-1 mt-4">
              Tags
              <span className="text-sm font-normal ml-2 text-gray-400">
                (Please select a maximum of 3)
              </span>
            </label>
            {tags.length > 0 ? (
              <div className="flex">
                {tags.map((tag) => (
                  <button
                    type="button"
                    onClick={() =>
                      setFormData({
                        ...formData,
                        tags: tags.filter((t) => t !== tag),
                      })
                    }
                    className="tag mr-2"
                  >
                    {tag}
                  </button>
                ))}
              </div>
            ) : (
              <h1>No tags selected!</h1>
            )}

            <span className="text-sm text-gray-400 text-center mt-3">
              Available Tags
            </span>
            <div className="flex flex-wrap border-t border-gray-300 pt-2">
              {getTagArray()
                .filter((t) => !tags.includes(t))
                .map((tag) => (
                  <button
                    type="button"
                    onClick={() => handleAddTag(tag)}
                    className="tag mr-2"
                  >
                    {tag}
                  </button>
                ))}
            </div>

            <button type="submit" className="btn-primary-md mt-4">
              Create
            </button>
          </form>
        </main>
      )}
    </>
  );
}

export default CreatePost;
