import Review from "../../types/Review";
import { MdStar, MdStarBorder, MdStarHalf } from "react-icons/md";

interface IProps {
  review: Review;
  primaryGenre: string;
  secondaryGenre: string;
}

const ReviewDetails = ({ review, primaryGenre, secondaryGenre }: IProps) => {
  const stars = (level: number, val: number) => {
    if (val >= level)
      return (
        <div className="text-yellow-400">
          <MdStar size="18" />
        </div>
      );
    else {
      if (val + 0.5 >= level)
        return (
          <div className="text-yellow-400">
            <MdStarHalf size="18" />
          </div>
        );
      else
        return (
          <div className="text-yellow-400">
            <MdStarBorder size="18" />
          </div>
        );
    }
  };

  return (
    <div className="pb-3">
      <div className="flex flex-wrap justify-center items-center">
        {/* Plot */}
        <div className="flex flex-col items-center px-3 pt-1">
          <h6 className="text-sm text-gray-400">Plot</h6>
          <div className="flex items-center">
            {stars(1, review.ratingDetails.plot)}
            {stars(2, review.ratingDetails.plot)}
            {stars(3, review.ratingDetails.plot)}
            {stars(4, review.ratingDetails.plot)}
            {stars(5, review.ratingDetails.plot)}
          </div>
        </div>
        {/* Writing */}
        <div className="flex flex-col items-center px-3 pt-1">
          <h6 className="text-sm text-gray-400">Writing</h6>
          <div className="flex items-center">
            {stars(1, review.ratingDetails.writing)}
            {stars(2, review.ratingDetails.writing)}
            {stars(3, review.ratingDetails.writing)}
            {stars(4, review.ratingDetails.writing)}
            {stars(5, review.ratingDetails.writing)}
          </div>
        </div>
        {/* Characters */}
        <div className="flex flex-col items-center px-3 pt-1">
          <h6 className="text-sm text-gray-400">Characters</h6>
          <div className="flex items-center">
            {stars(1, review.ratingDetails.characters)}
            {stars(2, review.ratingDetails.characters)}
            {stars(3, review.ratingDetails.characters)}
            {stars(4, review.ratingDetails.characters)}
            {stars(5, review.ratingDetails.characters)}
          </div>
        </div>

        {/* Primary Genre */}
        <div className="flex flex-col items-center px-3 pt-1">
          <h6 className="text-sm text-gray-400">{primaryGenre} Value</h6>
          <div className="flex items-center">
            {stars(1, review.ratingDetails.primaryGenre)}
            {stars(2, review.ratingDetails.primaryGenre)}
            {stars(3, review.ratingDetails.primaryGenre)}
            {stars(4, review.ratingDetails.primaryGenre)}
            {stars(5, review.ratingDetails.primaryGenre)}
          </div>
        </div>

        {/* Secondary Genre */}
        {secondaryGenre !== "None" && (
          <div className="flex flex-col items-center px-3 pt-1">
            <h6 className="text-sm text-gray-400">{secondaryGenre} Value</h6>
            <div className="flex items-center">
              {stars(1, review.ratingDetails.secondaryGenre)}
              {stars(2, review.ratingDetails.secondaryGenre)}
              {stars(3, review.ratingDetails.secondaryGenre)}
              {stars(4, review.ratingDetails.secondaryGenre)}
              {stars(5, review.ratingDetails.secondaryGenre)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewDetails;
