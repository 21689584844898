import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase.config";
import docToPost from "./../convert/docToPost";

const fetchSinglePost = async (id: string) => {
  const docRef = doc(db, "posts", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docToPost(docSnap);
  }

  return null;
};

export default fetchSinglePost;
