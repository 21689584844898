import Media from "react-media";
import Post from "../types/Post";
import CardDesktop from "./CardDesktop";
import CardMobile from "./CardMobile";

interface IProps {
  post: Post;
}

const Card = ({ post }: IProps) => {
  return (
    <Media
      queries={{
        small: "(max-width: 767px)",
        medium: "(min-width: 768px)",
      }}
    >
      {(matches) => (
        <>
          {matches.small && <CardMobile post={post} />}
          {matches.medium && <CardDesktop post={post} />}
        </>
      )}
    </Media>
  );
};

export default Card;
