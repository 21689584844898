import { Link } from "react-router-dom";
import { useAuthStatus } from "../hooks/useAuthStatus";
import getUsername from "../lib/getUsername";

function Sidebar() {
  const { loggedIn } = useAuthStatus();
  const username = getUsername();

  return (
    <div className="sidebar p-8">
      <h1 className="text-2xl font-semibold my-6">ScriptOwl</h1>
      <div className="flex flex-col justify-between h-5/6">
        <div className="flex flex-col items-start">
          <Link to="/" className="sidebar-item">
            Home
          </Link>
          <Link to="/popular" className="sidebar-item">
            Popular
          </Link>
          <Link to="/categories" className="sidebar-item">
            Categories
          </Link>
          <Link to="/forum" className="sidebar-item">
            Forum
          </Link>
        </div>
        <div>
          {loggedIn ? (
            <span>
              Logged in{username ? " as" : "."}
              <Link to="/profile" className="text-primary ml-1.5">
                {username ? username : "My profile"}
              </Link>
            </span>
          ) : (
            <span>
              Not logged in.
              <Link to="/sign-in" className="text-primary ml-1.5">
                Log in?
              </Link>
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
