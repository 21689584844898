import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db, auth } from "../firebase.config";

const signUpUser = async (name: string, email: string, password: string) => {
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  );

  if (auth.currentUser) {
    await updateProfile(auth.currentUser, {
      displayName: name,
    });
  }

  const user = userCredential.user;

  const formData = { name, email, timestamp: serverTimestamp() };

  await setDoc(doc(db, "users", user.uid), formData);
};

export default signUpUser;
