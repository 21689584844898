import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  where,
} from "firebase/firestore";
import { db } from "../../firebase.config";
import Post from "../../types/Post";
import docToPost from "./../convert/docToPost";

const fetchPostsByUser = async (userId: string) => {
  try {
    // Get reference
    const postsRef = collection(db, "posts");

    // Create a query
    const q = query(
      postsRef,
      where("userId", "==", userId),
      orderBy("timeStamp", "desc"),
      limit(10)
    );

    // Execute query
    const querySnap = await getDocs(q);

    const posts: Array<Post> = [];

    querySnap.forEach((doc) => {
      return posts.push(docToPost(doc));
    });

    return posts;
  } catch (error) {
    console.log(error);
  }
};

export default fetchPostsByUser;
