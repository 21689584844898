import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db, auth } from "../firebase.config";
import { doc, updateDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import { MdCreate } from "react-icons/md";
import Post from "../types/Post";
import fetchPostsByUser from "../lib/fetch/fetchPostsByUser";
import reviewText from "../lib/tools/reviewText";

type FormData = {
  name: string;
  email: string;
};

function Profile() {
  const getFormData = (): FormData => {
    if (auth.currentUser?.displayName && auth.currentUser.email) {
      return {
        name: auth.currentUser.displayName,
        email: auth.currentUser.email,
      };
    } else
      return {
        name: "",
        email: "",
      };
  };

  const [changeDetails, setChangeDetails] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>(getFormData());
  const [posts, setPosts] = useState<Post[]>();

  useEffect(() => {
    if (auth.currentUser) {
      fetchPostsByUser(auth.currentUser.uid).then((res) => setPosts(res));
    }
  }, []);

  const navigate = useNavigate();

  const onLogout = () => {
    auth.signOut();
    navigate("/");
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async () => {
    try {
      if (auth.currentUser && auth.currentUser.displayName !== formData.name) {
        // Update display name in Firebase
        await updateProfile(auth.currentUser, {
          displayName: formData.name,
        });

        // Update in Firestore
        const userRef = doc(db, "users", auth.currentUser.uid);
        await updateDoc(userRef, {
          name: formData.name,
        });

        toast.success("Updated!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
      console.log(error);
    }
  };

  return (
    <main className="max-w-md mx-auto">
      <Helmet>
        <title>ScriptOwl | Profile</title>
      </Helmet>
      <h1 className="page-title">Profile</h1>

      {formData.name && (
        <>
          <div className="flex justify-between items-center mb-4">
            <p>Personal details</p>
            <button
              className="text-primary font-semibold"
              onClick={() => {
                changeDetails && onSubmit();
                setChangeDetails((prevState) => !prevState);
              }}
            >
              {changeDetails ? "Done" : "Change"}
            </button>
          </div>

          <form className="flex flex-col">
            <input
              type="text"
              className={changeDetails ? "form-input" : "form-input-inactive"}
              placeholder="Name"
              id="name"
              value={formData.name}
              onChange={onChange}
              disabled={!changeDetails}
            />
            <input
              type="email"
              className="form-input-inactive"
              placeholder="Email"
              id="email"
              value={formData.email}
              onChange={onChange}
              disabled={true}
            />
          </form>
          <button className="btn-primary-md" onClick={onLogout}>
            Logout
          </button>

          {/* Posts section */}
          <div className="pt-8 flex justify-between items-center">
            <h1 className="page-title">My Posts</h1>
            <Link to={`/create`}>
              <button className="btn-primary-md flex justify-between">
                Create a Post
                <MdCreate size="25" className="ml-1" />
              </button>
            </Link>
          </div>
          {posts && (
            <>
              {posts.map((post) => (
                <Link to={`/post/${post.id}`}>
                  <div
                    key={post.id}
                    className="bg-white rounded-lg px-4 py-2 flex justify-between items-center shadow-md"
                  >
                    <h6 className="font-bold">{post.title}</h6>
                    <div>
                      <h6>
                        {post.stats.rating.toFixed(1)}/5
                        <span className="text-gray-400 ml-2">
                          {reviewText(post)}
                        </span>
                      </h6>
                    </div>
                  </div>
                </Link>
              ))}
            </>
          )}
        </>
      )}
    </main>
  );
}

export default Profile;
