import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import Post from "../../types/Post";

const docToPost = (doc: QueryDocumentSnapshot<DocumentData>): Post => {
  return {
    id: doc.id,
    title: doc.data().title,
    description: doc.data().description,
    postType: doc.data().postType,
    primaryGenre: doc.data().primaryGenre,
    secondaryGenre: doc.data().secondaryGenre,
    tags: doc.data().tags,
    storageUrl: doc.data().storageUrl,
    userId: doc.data().userId,
    userName: doc.data().userName,
    stats: doc.data().stats,
    timeStamp: doc.data().timeStamp.toDate(),
    lastUpdated: doc.data().lastUpdated.toDate(),
  };
};

export default docToPost;
