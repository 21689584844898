import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStatus } from "../hooks/useAuthStatus";
import getUsername from "../lib/getUsername";
import { MdMenu, MdMenuOpen } from "react-icons/md";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { loggedIn } = useAuthStatus();
  const username = getUsername();

  const navigate = useNavigate();

  const handleClick = (path: string) => {
    setMenuOpen(false);
    navigate(path);
  };

  return (
    <div className="navbar">
      <div className="flex justify-between items-center p-4">
        <h1 className="text-xl font-semibold">ScriptOwl</h1>
        {menuOpen ? (
          <button onClick={() => setMenuOpen((prevState) => !prevState)}>
            <MdMenuOpen className="text-white text-3xl" />
          </button>
        ) : (
          <button onClick={() => setMenuOpen((prevState) => !prevState)}>
            <MdMenu className="text-white text-3xl" />
          </button>
        )}
      </div>
      {menuOpen && (
        <div className="nav-dropdown">
          <div className="flex flex-col items-end p-4">
            <button onClick={() => handleClick("/")} className="sidebar-item">
              Home
            </button>
            <button
              onClick={() => handleClick("/popular")}
              className="sidebar-item"
            >
              Popular
            </button>
            <button
              onClick={() => handleClick("/categories")}
              className="sidebar-item"
            >
              Categories
            </button>
            <button
              onClick={() => handleClick("/forum")}
              className="sidebar-item"
            >
              Forum
            </button>
            {loggedIn ? (
              <>
                <span className="mt-4">Logged in{username ? " as" : "."}</span>
                <button
                  onClick={() => handleClick("/profile")}
                  className="text-primary ml-1.5"
                >
                  {username ? username : "My profile"}
                </button>
              </>
            ) : (
              <>
                <span className="mt-4">Not logged in.</span>
                <button
                  onClick={() => handleClick("/sign-in")}
                  className="text-primary ml-1.5"
                >
                  Log in?
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
